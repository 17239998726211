import React, { createContext, useState, useEffect } from "react";

export const TransitionContext = createContext({
  isTransitioning: false,
  setIsTransitioning: () => {},
});

export const TransitionProvider = ({ children }) => {
  const [isTransitioning, setIsTransitioning] = useState(false);
  
  useEffect(() => {
    // Reset transition state after navigation completes
    if (isTransitioning) {
      const timer = setTimeout(() => {
        setIsTransitioning(false);
      }, 800); // Slightly longer than transition time
      
      return () => clearTimeout(timer);
    }
  }, [isTransitioning]);
  
  return (
    <TransitionContext.Provider value={{ isTransitioning, setIsTransitioning }}>
      {children}
    </TransitionContext.Provider>
  );
};

export const useTransition = () => React.useContext(TransitionContext);