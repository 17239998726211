import React from "react";
import { TransitionProvider } from "./src/context/TransitionContext";

// Wrap the root element to provide the transition context
export const wrapRootElement = ({ element }) => {
  return <TransitionProvider>{element}</TransitionProvider>;
};


// Scroll to top when navigating to a new page
export const onRouteUpdate = () => {
  // Delay scrolling to top to ensure the new page has rendered
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
  
  return true;
};

// Add a transition container around each page
export const wrapPageElement = ({ element }) => {
  return (
    <div className="page-transition-container">
      {element}
    </div>
  );
};

// Preserve scroll position during page navigation
export const shouldUpdateScroll = () => {
  return false;
};
