import { useStaticQuery, graphql } from "gatsby";

const useLayoutQuery = () => {
  return useStaticQuery(graphql`
    fragment ProjectCard on SanityProjects {
      id
      title
      orderRank
      slug {
        current
      }
      mainImage {
        asset {
          gatsbyImageData(
            layout: CONSTRAINED,
            formats: [AUTO, WEBP],
            placeholder: NONE,
          )
        }
      }
      sketchImage {
        asset {
          gatsbyImageData(
            layout: CONSTRAINED,
            formats: [AUTO, WEBP],
            placeholder: NONE,
          )
        }
      }
      ## date(formatString: "ddd DD MMMM"
    }
    fragment JournalCard on SanityJournal {
      id
      publishedAt
      orderRank
      journalContentType
      title
      _rawTextAreaColumn
      slug {
        current
      }
      featuredImage {
        asset {
          gatsbyImageData(
            layout: CONSTRAINED,
            formats: [AUTO, WEBP],
            placeholder: NONE
          )
        }
      }
      ## date(formatString: "ddd DD MMMM"
    }
    query SiteAndArtistsQuery {
      site {
        siteMetadata {
          title
        }
      }
     ## allSanityArtist {
      ##    nodes {
        ##      name
        ##     discipline
        ##      slug {
          ##        current
          ##      }
          ##        portraitImage {
    ##         asset {
      ##           url
              ##             publicUrl
              ##          }
              ##        }
              ##       landscapeImage {
                ##         asset {
                  ##          url
              ##            publicUrl
              ##         }
              ##        }
              ##     }
              ##     }
    }
  `);
};

export default useLayoutQuery;