import React, { Component } from 'react';
import Link from '../utils/link';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import fallbackImage from '../assets/images/newlstter-image.png'; // Keep fallback image


function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class Form extends Component {
  state = {
    result: '',
    msg: ''
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();

    if (!this.refs.form.checkValidity()) return false;

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => {
        this.setState({ result: "success" });
      })
      .catch(error => this.setState({ result: "fail", msg: error }));
  };

  render() {
    let props = {
      ref: "form",
      name: "Wadhal Newsletter Signup",
      className: "form",
      onSubmit: this.handleSubmit,
      "data-netlify": "true",
      "data-netlify-honeypot": "bot-field",
    };

    if (this.state.result === "success")
      return (
        <p className="popup__success">
          Thanks for subscribing.
        </p>
      );

    return (
      <form netlify {...props}>
        <div className="row">
          <div className="col">
            <div className='col__details'>
              <input
                type="email"
                name="email"
                placeholder=""
                onChange={this.handleChange}
                required
              />
            </div>
          </div>

          {/* <div className="col">
            <div className="checkbox">
              <input
                type="checkbox"
                name="subscribe"
                required="required"
                id="subscribe"
                onChange={this.handleChange}
              />
              <label htmlFor='subscribe'>
                I consent to the <Link to='/legal/'>Terms and Conditions</Link>
              </label>
            </div>
          </div> */}

          <div className="col">
            <div className="btn-container">
              <button type="submit" className="btn">
              <svg width="12" height="11" viewBox="0 0 12 11" fill="none">
                <path d="M-2.83534e-08 5.5L11 5.5M11 5.5L6.5 10M11 5.5L6.5 1" stroke="black"/>
              </svg>
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

// Create a wrapper component that will handle the query
const NewsletterPopupWithQuery = props => (
  <StaticQuery
    query={graphql`
      query NewsletterImageQuery {
        sanitySiteSettings(_id: {eq: "siteSettings"}) {
          newsletterImage {
            asset {
              gatsbyImageData(width: 400, layout: CONSTRAINED)
              url
            }
          }
        }
      }
    `}
    render={data => (
      <NewsletterPopup 
        {...props} 
        newsletterImage={data.sanitySiteSettings?.newsletterImage?.asset}
      />
    )}
  />
);

class NewsletterPopup extends Component {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
    this.state = {
      hasSeenPopup: false
    };
  }

  componentDidMount() {
    // Check if user has seen the popup before
    const hasSeenPopup = localStorage.getItem('hasSeenNewsletter');
    
    if (!hasSeenPopup) {
      // Set timeout to show popup after 10 seconds
      this.popupTimer = setTimeout(() => {
        if (this.props.showPopup) {
          this.props.showPopup();
          // Set localStorage flag
          localStorage.setItem('hasSeenNewsletter', 'true');
        }
      }, 5000);
    }
  }

  componentWillUnmount() {
    // Clean up - remove the blur effect when component unmounts
    const mainElement = document.querySelector('main');
    if (mainElement) {
      mainElement.classList.remove('blur-background');
    }
    
    // Clear the timer to prevent memory leaks
    if (this.popupTimer) {
      clearTimeout(this.popupTimer);
    }
  }

  handleOverlayClick = (event) => {
    if (event.target.classList.contains('modal-overlay')) {
      this.props.hidePopup();
    }
  }

  componentDidUpdate(prevProps) {
    const { show } = this.props;
    const mainElement = document.querySelector('main');
    
    if (show !== prevProps.show) {
      if (show && mainElement) {
        mainElement.classList.add('blur-background');
      } else if (mainElement) {
        mainElement.classList.remove('blur-background');
      }
    }
  }


  render() {
    const { show, hidePopup, newsletterImage } = this.props;

    return (
      <div 
        className={show ? 'modal-overlay active' : 'modal-overlay'}
        onClick={this.handleOverlayClick}
      >
        <div className="modal">
          <button
            type='button'
            className='modal__close'
            onClick={hidePopup}
          >
            {/* <Close color={'#633B2F'} /> */}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" >
              <path d="M0.393311 11.6066L10.9999 1M10.9999 11.6066L0.393311 1" stroke="black"/>
            </svg>

          </button>
          <div className="modal__content">
            <div className='modal__col'>
            <div className='modal__image'>
  {newsletterImage?.gatsbyImageData ? (
    <GatsbyImage
      image={getImage(newsletterImage.gatsbyImageData)}
      alt="Subscribe to our newsletter"
    />
    
  ) : (
    <>
    </>
  )}
</div>
            </div>
            <div className='modal__col'>
              <h3>Subscribe for stories<br/>from our Journal</h3>

              <div className='modal__form'>
                <Form hidePopup={hidePopup} />
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewsletterPopupWithQuery;

